$(document).on('turbolinks:load', () => {
  let currentlyScrolling = false;
  let SCROLL_AREA_HEIGHT = 40; // Distance from window's top and bottom edge.

  $("[data-behaviour='sortable']").sortable({
    scroll: true,

    sort: function(event, ui) {
      if (currentlyScrolling) {
        return;
      }

      let windowHeight   = $(window).height();
      let mouseYPosition = event.clientY;

      if (mouseYPosition < SCROLL_AREA_HEIGHT) {
        currentlyScrolling = true;

        $('html, body').animate({
          scrollTop: "-=" + windowHeight / 8 + "px" // Scroll up half of window height.
        },
        400, // 400ms animation.
        function() {
          currentlyScrolling = false;
        });

      } else if (mouseYPosition > (windowHeight - SCROLL_AREA_HEIGHT)) {

        currentlyScrolling = true;

        $('html, body').animate({
          scrollTop: "+=" + windowHeight / 8 + "px" // Scroll down half of window height.
        },
        400, // 400ms animation.
        function() {
          currentlyScrolling = false;
        });

      }
    },
    update: function (e, ui) {
      if($(this).data("url") !== undefined){
        $.ajax({
          url: $(this).data("url"),
          type: "PUT",
          data: $(this).sortable("serialize")
        });
      }
    }
  });

  $("[data-behaviour='sortable-table']").sortable({
    scroll: true,
    helper: fixWidthHelper,
    update: function (e, ui) {
      if($(this).data("url") !== undefined){
        $.ajax({
          url: $(this).data("url"),
          type: "PUT",
          data: $(this).sortable("serialize")
        });
      }
    }
  });

  function fixWidthHelper(e, ui) {
    ui.children().each(function() {
        $(this).width($(this).width());
    });
    return ui;
  }
});
