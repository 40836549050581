document.addEventListener("turbolinks:load", () => {
  const addBtn = document.querySelector('.install-button');
  // Check if button is on the page
  if (addBtn != undefined) {
    // Register service worker to control making site work offline
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/serviceworker.js', { scope: '/' })
        .then(() => { console.log('Service Worker Registered'); });
    }

    // Code to handle install prompt
    let deferredPrompt;
    addBtn.style.display = 'none';

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI to notify the user they can add to home screen
      addBtn.style.display = 'block';

      addBtn.addEventListener('click', () => {
        // hide our user interface that shows our A2HS button
        addBtn.style.display = 'none';
        // Show the prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then(() => {
          deferredPrompt = null;
        });
      });
    });
  }
})
