document.addEventListener("turbolinks:load", function() {
  addFormRow();
  removeFormRow();
  addTooltip();
  removeTooltip();
})

function addFormRow() {
  var clickCount = 0;

  $( "#add-svcn" ).click(function() {
    //append a new form row to the end of the form
    clickCount ++;
    var $lastFormRow = $(".field.is-grouped.is-horizontal").last();
    $lastFormRow.after(generateHtml(clickCount));
  });
}

function removeFormRow() {
  $( "#remove-svcn" ).click(function() {
    // make sure can't get rid of all rows entirely
    if (noRowsLeft()) {return;}
    //remove new form row at the end of the form
    $(".new-form-row").last().remove()
  });
}

function generateHtml(count) {
  return `
    <div class="field is-grouped is-horizontal has-padding-right-78 new-form-row">
      <div class="field-body">
        <div class="field">
          <label class="label" for="critical_number">Critical Number</label>
          <div class="control">
            <input class="input" size="140" type="text" name="new[smart_vision_critical_number_${count}][critical_number]" id="new_smart_vision_critical_number[critical_number]">
          </div>
        </div>

        <div class="field">
          <label class="label" for="ideal_value">Ideal Value</label>
          <div class="control">
            <input class="input" type="text" name="new[smart_vision_critical_number_${count}][ideal_value]" id="new_smart_vision_critical_number[ideal_value]">
          </div>
        </div>

        <div class="field">
          <label class="label" for="ok_value">Ok Value</label>
          <div class="control">
            <input class="input" type="text" name="new[smart_vision_critical_number_${count}][ok_value]" id="new_smart_vision_critical_number[ok_value]">
          </div>
        </div>

        <div class="field">
          <label class="label" for="poor_value">Poor Value</label>
          <div class="control">
            <input class="input" type="text" name="new[smart_vision_critical_number_${count}][poor_value]" id="new_smart_vision_critical_number[poor_value]">
          </div>
        </div>
      </div>
    </div>
  `;
}

function addTooltip() {
  tippy('#add-svcn', {
    content: "Add another critical number row",
    animation: "perspective-extreme",
    arrow: false
  });
}

function removeTooltip() {
  tippy('#remove-svcn', {
    content: "Remove critical number row",
    animation: "perspective-extreme",
    arrow: false
  });
}

function noRowsLeft() {
  var $allFormRows = $(".field.is-grouped.is-horizontal");
  if ($allFormRows.length == 1) {
    return true;
  }
}
