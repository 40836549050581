document.addEventListener("turbolinks:load", function() {
  //only invoke Quill JS functions if WYSIWYG editor is present on the page
  if ($('.quill-text-box').length) {
    initializeQuillEditor();
    quillTextToBackEnd();
  }
})

var initializeQuillEditor = function(){
  var options = {
    theme: 'snow'
  };
  var quill = new Quill('.quill-text-box', options);
}

// When a form is submitted with a quill editor
// this method will take the text from the editor
// and add it to the relevant hidden field to send to the backend
// the id of the hidden field should be added to the form tag like so:
// <%= form_for([@company, @smart_vision], data: { hidden_field_id: "smart_vision_core_purpose" }) do |f| %>
var quillTextToBackEnd = function () {
  $("form[data-submit-listener-id='wysiwyg-form']").submit(function (e) {
    var text = document.querySelector(".ql-editor").innerHTML
    var hiddenField = e.target.getAttribute("data-hidden-field-id")
    $("#"+hiddenField).val(text);
  });
}
