import { delegate } from 'tippy.js';

document.addEventListener("turbolinks:load", function() {
  addFormRow();
  removeFormRow();
  addTooltip();
  removeTooltip();
  accountableUserTooltip();
  validate();
  deleteWarning();
})

function deleteWarning() {
  $(".delete-priority-linked-rock").change(function() {
    if ($(this).prop('checked')) {
      alert("WARNING: Deleting this Priority will delete the linked Smart Rock and its actions.");
    }
  });
}

function addFormRow() {
  var clickCount = 0;

  $( "#add-priority" ).click(function() {

    alert("IMPORTANT: Creating a new Priority will automatically create a Smart Rock for the accountable user on their Smart90.\n\nThe new Smart Rock cannot be deleted and cannot be reassigned.\n\nAre you sure you want to continue?");

    // only allow 7 rows to be added
    if (reachedMaxRows()) {return;}
    //append a new form row to the end of the form
    clickCount ++;
    var $lastFormRow = $(".field.is-grouped.is-horizontal").last();

    var userOptions = "";
    var userId = $("#user_id").val();
    $.each($.parseJSON($("#users-for-js").text()), function(i,v) {
      if (v[0] == userId) {
        userOptions += `<option selected="selected" value="${v[0]}">${v[1]}</option>`;
      } else {
        userOptions += `<option value="${v[0]}">${v[1]}</option>`;
      }
    });

    $lastFormRow.after(generateHtml(clickCount, userOptions));
  });
}

function removeFormRow() {
  $(document).on('click', ".priority-remove-icon", function() {
    //remove the row that user has clicked the remove icon on
    $(this).closest(".new-form-row").remove();
  });
}

function generateHtml(count, users) {
  return `
  <div class="field is-grouped is-horizontal new-form-row box has-pointer">
    <div class="field-body">
      <div class="field is-expanded">
        <label class="label required has-pointer" for="title">Title</label>
        <div class="columns is-gapless">
          <div class="column">
            <div class="control is-expanded">
              <input class="input priority_input" type="text" name="new[smart_vision_priorities_${count}][title]" id="priority_${count}" required>
            </div>
          </div>
        <div class="column is-2 has-text-centered" style="width: 180px;">
          <a href="#" onclick="smartifyPriority(event, null);" class="button is-info is-outlined">SMARTify with AI</a>
        </div>
      </div>
      </div>

      <div class="field is-narrow" id="accountable-user-tooltip">
        <label class="label has-pointer required" for="accountable_user">Accountable user</label>
        <div class="control">
          <div class="select">
            <select name="new[smart_vision_priorities_${count}][user_id]">
              ${users}
            </select>
          </div>
        </div>
      </div>

      <div class="field is-narrow">
        <div class="control">
          <span class="icon has-text-danger is-large priority-remove-icon">
            <i class="fas fa-minus-square fa-2x has-pointer" id="remove-priority"></i>
          </span>
        </div>
      </div>
    </div>
    <input value="0" type="hidden" name="new[smart_vision_priorities_${count}[position]" id="new[smart_vision_priorities_${count}][position]" data-selector-name="position">
  </div>
  `;
}

function generateHtmlOptions() {
  var progressIndicators = {
    "Not started or Hit Problem": 0,
    "Early Days but Progressing": 20,
    "Making Head-way": 40,
    "About half-way": 50,
    "Objective in sight": 60,
    "Final Approach": 80,
    "Completed": 100
  };

  var string = "";

  Object.keys(progressIndicators).forEach(key=>{
    string += `<option value="${progressIndicators[key]}">${key}</option>`;
  });

  return string;
}

function addTooltip() {
  tippy('#add-priority', {
    content: "Add another priority row",
    animation: "perspective-extreme",
    arrow: false
  });
}

function removeTooltip() {
  if ($("#priorities-page").length) {
    delegate('body', {
      target: '#remove-priority',
      content: "Remove priority row",
      animation: "perspective-extreme",
      arrow: false
    });
  }
}

function accountableUserTooltip() {
  if ($("#priorities-page").length) {
    delegate('html', {
      target: '#accountable-user-tooltip',
      content: "Creating a priority with an accountable user will automatically create a smart rock for the accountable user for the given priority.",
      animation: "perspective-extreme",
      arrow: false
    });
  }
}

function reachedMaxRows() {
  var $allFormRows = $(".field.is-grouped.is-horizontal");
  if ($allFormRows.length == 7) {
    highlightMaximum();
    return true;
  }
}

function validate() {
  $("form[data-submit-listener-id='priorities-form']").submit(function (e) {
    setPositions();
    var formRows = $(".field.is-grouped.is-horizontal").length;
    var rowsToBeDeleted = $('input[type=checkbox]:checked').length;
    var rowsBeingSubmitted = formRows - rowsToBeDeleted;
    if (rowsBeingSubmitted > 7) {
      e.preventDefault();
      highlightMaximum();
      return false;
    }
  });
}

function highlightMaximum() {
  var $maxPriorities = $("#max-priorities");
  $maxPriorities.addClass('has-text-danger');
  setTimeout(function () {
    $maxPriorities.removeClass('has-text-danger');
  }, 250);
}

function setPositions() {
  $("form[data-submit-listener-id='priorities-form']").children(".field.is-grouped").each(function(index) {
    $(this).find('input[data-selector-name="position"]').val(index+1);
  });
}
