document.addEventListener("turbolinks:load", function() {
  preventFormSubmit();
  accordionLink();
})

function preventFormSubmit() {
  $( "#smart90-select" ).submit(function(e) {
    var selected = $("select option:selected").text();
    if (selected === "Select Smart 90") {
      e.preventDefault();
      return false;
    }
  });
}

function accordionLink() {
  $('.inner-link').click(function (e) {
    e.stopPropagation();
    var companyId = $("#company-id").data().companyId
    var smartVisionId = $("#smart-vision-id").data().smartVisionId
    var userId = $("#user-id").data().userId
    var smart90Id = $("#smart90-id").data().smart90Id
    var smartRockId = e.currentTarget.attributes["data-smart-rock-id"].value;
    var url = `/companies/${companyId}/smart_visions/${smartVisionId}/users/${userId}/smart90s/${smart90Id}/smart_rocks/${smartRockId}`
    var faq_url = `/companies/${companyId}/help_pages/0?page_title=quarter_must_do_faq`

    if (e.currentTarget.classList.contains("fa-pencil-alt")) {
      window.location.href = `${url}/edit`;
    } else if (e.currentTarget.classList.contains("fa-question-circle")) {
      window.open(faq_url, '_blank');
    } else {
      event.preventDefault();
      var choice = confirm("Are you sure you want to delete smart rock?");
      if (choice) {
        $.ajax({
          type: "DELETE",
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          url: url
        });
      }
    }

    return false;
  });
}
