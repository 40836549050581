document.addEventListener("turbolinks:load", function() {
  checkPasswordMatch();
})

function checkPasswordMatch() {  
  $("#account_settings_form").submit(function(e) {
    password = $( "input[name='user[password]']" ).val();
    passwordConfirmation = $( "input[name='user[password_confirmation]']" ).val();
    if (password !== password_confirmation) {
      e.preventDefault();
      alert("password confirmation must match password");
      return false;
    }
  });
}