// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")

// After installing a 3rd party JS library through Yarn we need it to be included in webpacker
// Instructions of how to do this are dependent on each JS library and should be in their getting started docs
// Webpacker/ES6 also treats each JS file as its own scope / seperate module
// apparently this is good for de-cluttering the global scope
// this means you need to import a 3rd party library into each JS file you want to use it in
// or you can import it here and make it available in a global variable so it can be used everywhere (helpful for a library you will use everywhere like jQuery)


global.$ = require("jquery");
require("jquery-ui");
require('jquery-ui-touch-punch');


import tippy from "tippy.js";
global.tippy = tippy;

import bulmaCollapsible from '@creativebulma/bulma-collapsible';
global.bulmaCollapsible = bulmaCollapsible;

import bulmaSlider from 'bulma-slider/dist/js/bulma-slider.min.js';
global.bulmaSlider = bulmaSlider;

import chartJs from 'chart.js/dist/chart.min.js';
global.chartJs = chartJs;

require("custom/clear_search");
require("custom/password_match");
require("custom/quill");
require("custom/svk_form");
require("custom/password_strength");
require("custom/burger_menu");
require("custom/classie");
require("custom/time_circles_src");
require("custom/time_circles");
require("custom/priorities_form");
require("custom/smart90");
require("custom/smart_rocks_collapse");
require("custom/new_smart_rock_action_form");
require("custom/smart_vision_control_centre");
require("custom/svcn_form");
require("custom/sortable");
require("custom/initialize_tooltip");
require("custom/slider");
require("custom/loader");
require("@nathanvda/cocoon");
require("custom/scorecard");
require("custom/remove_attachments");
require("custom/file_upload");
require("custom/clear_input");
require("custom/install_button");
require("custom/chatbot");
require("custom/smartify");

// Required here to load service worker for PWA functionality
if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/serviceworker.js', { scope: '/' })
    .then(function(reg) {
      console.log('[Page] Service worker registered!');
    }).catch(function (e) {
      console.error('Error during service worker registration:', e);
    });
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

