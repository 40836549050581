document.addEventListener("turbolinks:load", function() {
  window.smartifyPriority = function(event, priority_id) {
    event.preventDefault();
    // Get closest input based on button clicked
    let target_input = $(event.target).closest(".columns").find(".priority_input")
    $.ajax({
      url: `/priorities_smartify`,
      type: "POST",
      data: {
        priority_id: priority_id,
        current_value: target_input.val()
      },
      success: function(response) {
        // Update form with response from controller
        target_input.val(response["data"]);
      },
      error: function(xhr, status, error) {
        console.error("Error:", error);
      }
    });
  }
});

