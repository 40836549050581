document.addEventListener("turbolinks:load", function() {
  if ($('#scorecard_kpis').length) {
    // limits the number of categories
    $('#scorecard_kpis').on('cocoon:after-insert', function() {
      check_to_hide_or_show_add_link();
    });

    $('#scorecard_kpis').on('cocoon:after-remove', function() {
      check_to_hide_or_show_add_link();
    });

    check_to_hide_or_show_add_link();

    function check_to_hide_or_show_add_link() {
      if ($('#scorecard_kpis .nested-fields:visible').length >= 5) {
        $('a.add_fields').hide();
        $('#breach_message').show();
      } else {
        $('a.add_fields').show();
        $('#breach_message').hide();
      }
    }
  }
})
