import { delegate } from 'tippy.js';

document.addEventListener("turbolinks:load", function() {
  addActionRow();
  removeActionRow();
  addTooltip();
  removeTooltip();
  setPositions();
  actionAccountableUserTooltip();
})

function addActionRow() {
  var clickCount = 0;
  $( "#add-action" ).click(function() {
    //append a new form row to the end of the form
    clickCount ++;
    var initial_users = $("#smart_rock_user_id").html()
    $("#sortable-action-container").append(generateHtml(clickCount, initial_users));
  });
}

function removeActionRow() {  
  $(document).on('click', "#remove-action", function() {
    // make sure can't get rid of all rows entirely
    //remove new form row which icon was clicked on
    $(this).closest(".new-action-form-row").remove();
  });
}


function generateHtml(count, users) {
  return `
    <div class="field is-grouped is-horizontal new-action-form-row box has-pointer">
      <div class="field-body">
        <div class="field">
          <label class="label required has-pointer" for="action">Action</label>
          <div class="control">
            <input class="input" type="text" name="actions[action_${count}][action]" required>
          </div>
        </div>

        <div class="field is-narrow" id="action-accountable-user">
          <label class="label has-pointer" for="accountable_user">Accountable user</label>
          <div class="control">
            <div class="select">
              <select name="actions[action_${count}][user_id]">
                ${users}
              </select>
            </div>
          </div>
        </div>

        <div class="field is-narrow">
          <label class="label has-pointer" for="due_date">Due Date</label>
          <div class="control">
            <input class="input" type="date" name="actions[action_${count}][due_date]">
          </div>
        </div>

        <div class="field is-narrow">
          <label class="label has-pointer" for="progress">Progress</label>
          <div class="control">
            <div class="select">
              <select name="actions[action_${count}][progress]" id="actions[action_${count}][progress]">
                ${generateHtmlProgressOptions()}
              </select>
            </div>
          </div>
        </div>

        <div class="field is-narrow">
          <div class="control">
            <span class="icon has-text-danger is-large action-remove-icon">
              <i class="fas fa-minus-square fa-2x has-pointer" id="remove-action"></i>
            </span>
          </div>
        </div>
      </div>
      <input value="0" type="hidden" name="actions[action_${count}][position]" id="actions[action_${count}][position]" data-selector-name="position">
    </div>
  `;
}

function generateHtmlProgressOptions() {
  var progressIndicators = {
    "Not started or Hit Problem": 0, 
    "Early Days but Progressing": 20, 
    "Making Head-way": 40, 
    "About half-way": 50, 
    "Objective in sight": 60, 
    "Final Approach": 80, 
    "Completed": 100
  };

  var string = "";
  
  Object.keys(progressIndicators).forEach(key=>{
    string += `<option value="${progressIndicators[key]}">${key}</option>`;
  });

  return string;
}

function addTooltip() {  
  tippy('#add-action', {
    content: "Add action row",
    animation: "perspective-extreme",
    arrow: false
  });
}

function removeTooltip() {
  if ($("#new-smart-rock-page").length) {  
    delegate('body', {
      target: '#remove-action',
      content: "Remove action row",
      animation: "perspective-extreme",
      arrow: false
    });
  }
}

function actionAccountableUserTooltip() {
  if ($("#new-smart-rock-page").length) {   
    delegate('html', {
      target: '#action-accountable-user',
      content: "Creating an action with an accountable user will automatically create the same action for that user, including the smart rock if it does not exist",
      animation: "perspective-extreme",
      arrow: false
    });
  }
}

function setPositions() {
  var $actionForm = $("form[data-submit-listener-id='actions-form']");  
  $actionForm.submit(function (e) {
    $actionForm.find(".field.is-grouped").each(function(index) {
      $(this).find('input[data-selector-name="position"]').val(index+1);
    });
  });
}
