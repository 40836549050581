document.addEventListener("turbolinks:load", function() {
  clearSearchInputs();
})

function clearSearchInputs(params) {
  $( "#clear-search" ).click(function() {
    $(':input','form').not(':button, :submit, :reset, :hidden').val('').removeAttr('checked').removeAttr('selected');
    $("input[value='Search']").click();
  });
}
